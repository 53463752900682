import * as React from "react";
import { Icon } from "react-icons-kit";
import { starFull } from "react-icons-kit/icomoon/starFull";
import axios from "axios";
import Image from "gatsby-image";
import { attemptTracking } from "@fitplan/lib/analytics";
import classnames from "classnames";
import Checkmark from "../icons/Checkmark";
import lifecycle from "react-pure-lifecycle";

import ClosedView from "./ClosedView";

import "./plan.scss";

const isOdd = n => !!(n & (1 == 1));
const isEven = n => !isOdd(n);

const getDescriptionText = (trainerName, planName) => {
    const aName = `${trainerName}${trainerName.slice(-1) === "s" ? "'" : "'s"}`;
    return `${aName} ${planName}`;
};

const useCurrencyByLocation = () => {
    const [location, setLocation] = React.useState("US");
    const [currency, setCurrency] = React.useState({});

    const geoIpUrl = "https://geoip-db.com/json/";

    const getCountryDataUrl = code =>
        `https://restcountries.eu/rest/v2/alpha/${code}`;

    React.useEffect(() => {
        fetch(geoIpUrl).then(response => {
            response.json().then(data => {
                setLocation(data.country_code);
            });
        });
    }, []);

    React.useEffect(() => {
        axios.get(getCountryDataUrl(location)).then(response => {
            setCurrency(response.data.currencies[0]);
        });
    }, [location]);

    return currency;
};

const getPlanByCurrency = (plans, currencyCode = "usd") => {
    const defaultCurrency = "usd";
    const foundSelectedPlan = plans.find(
        plan => plan.currency === currencyCode.toLowerCase()
    );
    const defaultPlan = plans.find(plan => plan.currency === defaultCurrency);

    return foundSelectedPlan || defaultPlan;
};

const Stars = () => (
    <div className="stars">
        <Icon icon={starFull} />
        <Icon icon={starFull} />
        <Icon icon={starFull} />
        <Icon icon={starFull} />
        <Icon icon={starFull} />
    </div>
);

const Transformation = ({ title, description, name, image }) => (
    <section className="transformation">
        {title ? (
            <h1 className="plan-view__headline">{title}</h1>
        ) : description ? (
            <Stars />
        ) : null}
        {description && (
            <div
                className={classnames("transformation__text-group", {
                    "transformation__text-group--no-padding": !image,
                })}
            >
                <p className="transformation__description">{`"${description}"`}</p>
                <p className="transformation__name">{`— ${name}`}</p>
            </div>
        )}
        {image && (
            <div className="transformation__image">
                <Image fluid={image.asset.fluid} />
            </div>
        )}
    </section>
);

const PlanInner = ({ sanityPlan, handleSubmit, activeView }) => {
    const currency = useCurrencyByLocation();
    const plan = getPlanByCurrency(
        sanityPlan.purchaseDetail.stripePlans,
        currency.code
    );

    return activeView ? (
        <div className="plan-view">
            <section className="banner">
                <Image fluid={sanityPlan.image.asset.fluid} />
                <h1 className="banner-headline">{sanityPlan.headline}</h1>
            </section>
            <section className="plan-features">
                <ul className="plan-features__list">
                    {sanityPlan.planFeatures.map(feature => (
                        <li
                            key={feature.title}
                            className="plan-features__list-item"
                        >
                            <Checkmark size={25} />
                            <div className="plan-features__list-item__text-group">
                                <h2 className="plan-features__list-item__title">
                                    {feature.title}
                                </h2>
                                <p className="plan-feature__list-item__description">
                                    {feature.description}
                                </p>
                            </div>
                        </li>
                    ))}
                </ul>
            </section>
            <section className="fitplan-features">
                <h1 className="plan-view__headline">
                    {sanityPlan.featuresHeader}
                </h1>
                {sanityPlan.fitplanFeatures.map((feature, i) => {
                    const sectionProps = Object.assign(
                        {},
                        {
                            key: feature.title,
                            className: classnames("fitplan-features__section", {
                                "fitplan-features__section--left": isOdd(i),
                            }),
                        },
                        isOdd(i) && {
                            style: {
                                background:
                                    "url(/images/rectangle.png) no-repeat top left",
                                backgroundSize: "100% 98%",
                            },
                        }
                    );
                    return (
                        <section {...sectionProps}>
                            <div className="fitplan-features__text-group">
                                <h1 className="fitplan-features__title">
                                    {feature.title}
                                </h1>
                                <p className="fitplan-features__description">
                                    {feature.description}
                                </p>
                            </div>
                            <div
                                className={classnames(
                                    "fitplan-features__image"
                                )}
                            >
                                <Image fluid={feature.image.asset.fluid} />
                            </div>
                        </section>
                    );
                })}
            </section>
            <section className="transformations">
                {sanityPlan.athlete.transformation && (
                    <Transformation {...sanityPlan.athlete.transformation} />
                )}
                <h1 className="plan-view__headline">
                    {sanityPlan.transformationsHeader}
                </h1>
                {sanityPlan.transformations.map(transformation => (
                    <Transformation
                        key={transformation.name}
                        {...transformation}
                    />
                ))}
            </section>
            <section className="call-to-action">
                <div className="call-to-action__column-group">
                    <div>
                        <h1 className="cta-amount">
                            <span className="cta-amount__symbol">
                                {currency.symbol}
                            </span>
                            {plan.amount / 100}
                        </h1>
                    </div>
                    <button
                        className="cta-purchase-button"
                        onClick={() => handleSubmit(plan)}
                    >
                        {sanityPlan.purchaseDetail.buttonText}
                    </button>
                </div>
                <p className="cta-amount__subtext--small">
                    {sanityPlan.purchaseDetail.purchaseDescriptionText}
                </p>
            </section>
        </div>
    ) : (
        <ClosedView
            stepText="1. Select Your Plan"
            description={getDescriptionText(
                sanityPlan.athlete.name,
                sanityPlan.name
            )}
            totalText="Total:"
            amount={plan.amount / 100}
            currencySymbol={currency.symbol}
            planInteval={plan.interval}
        />
    );
};

export const Plan = lifecycle({
    componentDidMount: () => {
        if (process.env.GATSBY_ACTIVE_ENV === "production") {
            attemptTracking(() => {
                window.fbq("init", "838071729653756");
                window.fbq("track", "PageView");
            });
        }
    },
})(PlanInner);
