import * as React from 'react';
import classnames from 'classnames';
import './input.scss';

const Input = ({ label, error, ...props }) => (
  <div
    className={classnames('input-group', {
      'input-group--error': error,
    })}
  >
    {label && (
      <label
        id={`${props.name}-label`}
        htmlFor={
          props.type === 'radio' ? `${props.value}-${props.name}` : props.name
        }
      >
        {label}
      </label>
    )}
    <input
      className={classnames('input-group__input', {
        'input-group__input--error': error,
        'input-group__input--disabled': props.disabled,
      })}
      aria-label={!label ? props.name : undefined}
      aria-labelledby={label ? `${props.name}-label` : undefined}
      id={props.type === 'radio' ? `${props.value}-${props.name}` : props.name}
      type="text"
      {...props}
    />
    <div className="input-group__error-message">
      {error && <p className="error-message__text">{error}</p>}
    </div>
  </div>
);

export default Input;

export const Radio = ({ label, ...props }) => (
  <div className={classnames('radio-group', {})}>
    <input
      className={classnames('radio-group__radio', {
        'radio-group__radio--disabled': props.disabled,
      })}
      aria-label={!label ? props.name : undefined}
      aria-labelledby={label ? `${props.name}-label` : undefined}
      id={`${props.value}-${props.name}`}
      type="text"
      {...props}
    />
    {label && (
      <label
        id={`${props.value}-${props.name}-label`}
        htmlFor={`${props.value}-${props.name}`}
      >
        {label}
      </label>
    )}
  </div>
);
