export const english = {
    "branchDeepLink": "https://fitplan.app.link/profsecretsmike",    
    "purchaseDetail": {
        "stripePlans": [
            {
                "_id": "35376dc4-a51c-4441-aaa5-5a27fc57f8c5",
                "currency": "usd",
                "amount": 5999,
                "interval": "onetime",
                "name": "Professional Secrets Onetime"
            }
        ],
        "purchaseDescriptionText": "Permanent access to my Pro Secrets Fitplan",
        "buttonText": "Buy Now",
        "beforeDiscountMultiplyer": 0.9
    },
    "accountForms": [
        {
            "altText": "Already have an account?",
            "formFields": [
                {
                    "name": "name",
                    "label": "Name",
                    "placeholder": "Name",
                    "type": "text"
                },
                {
                    "name": "username",
                    "label": "Email",
                    "placeholder": "Email",
                    "type": "email"
                },
                {
                    "name": "password",
                    "label": "Password",
                    "placeholder": "Password",
                    "type": "password"
                },
                {
                    "name": "password_repeat",
                    "label": "Password Repeat",
                    "placeholder": "Password Repeat",
                    "type": "password"
                }
            ],
            "radioGroup": [
                {
                    "name": "gender",
                    "label": "Male",
                    "type": "radio",
                    "value": "male"
                },
                {
                    "name": "gender",
                    "label": "Female",
                    "type": "radio",
                    "value": "female"
                },
                {
                    "name": "gender",
                    "label": "Other",
                    "type": "radio",
                    "value": "other"
                }
            ],
            "buttons": [
                {
                    "text": "Create Account",
                    "type": "submit"
                },
                {
                    "text": "Login",
                    "type": "submit"
                }
            ]
        },
        {
            "formFields": [
                {
                    "name": "username",
                    "label": "Email",
                    "placeholder": "Email",
                    "type": "email"
                },
                {
                    "name": "password",
                    "label": "Password",
                    "placeholder": "Password",
                    "type": "password"
                }
            ],
            "buttons": [
                {
                    "text": "Login",
                    "type": "submit"
                },
                {
                    "text": "Cancel",
                    "type": "cancel"
                }
            ]
        }
    ],
    "accountStepText": "2. Create Your Account",
    "planStepText": "1. Select Your Plan",
    "purchaseStepText": "3. Enter Payment"
}