import * as React from "react";
import { H1, H2 } from "./common";
import { ymcaAnalytics } from "./analytics";
import { SubscriptionBlock } from "../SubscriptionBlock";
import { IStripePlan } from "@fitplan/types";

const Subscribe: React.SFC<{
    onSubscribe: (plan: IStripePlan) => void;
}> = ({onSubscribe}) => (
    <SubscriptionBlock
        title={<H1>Mon Valley YMCA Member Discount</H1>}
        subtitle={<H2>Try Fitplan FREE for 7 days. You won’t be charged until your trial ends. Cancel anytime.</H2>}

        buttonText="START FREE"
        secondaryButtonText="CONTINUE"

        language="en"
        stripePlans={[
            { active: true, planId: "plan_F3rnOp43rPhzD9", id: "plan_F3rnOp43rPhzD9", currency: "usd", name: "Mon Valley YMCA Annual Membership", amount: 7199, interval: "year" as const, interval_count: 1, billingScheme:"", created: 0 },
            { active: true, planId: "plan_F3rn3W40XVnlFS", id: "plan_F3rn3W40XVnlFS", currency: "usd", name: "Mon Valley YMCA Quarterly Membership", amount: 2399, interval: "month" as const, interval_count: 3, billingScheme:"", created: 0 },
            { active: true, planId: "plan_F3rmViyaxcfsx3", id: "plan_F3rmViyaxcfsx3", currency: "usd", name: "Mon Valley YMCA Monthly Membership", amount: 999, interval: "month" as const, interval_count: 1, billingScheme:"", created: 0 },
        ]}

        onSubscribe={(plan) => {
            ymcaAnalytics.addToCart(plan.id);
            onSubscribe(plan);
        }}
    />
);

export default Subscribe;