import * as React from "react";
import styled from "@emotion/styled";
import { Button } from "@fitplan/components";
import { src, ButtonText, H1, H2, Cell } from "./common";

const Container = styled.div`
    display: grid;
    width: 100%;
    margin: 16px auto;

    grid-template-areas:
        "title          title"
        "first-text     first-pic"
        "second-pic     second-text"
        "third-text     third-pic"
        "subtitle       subtitle"
        "button         button";
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;

    justify-items: space-evenly;
    align-items: center;

    @media (min-width: 480px) {
        /* smartphones, Android phones, landscape iPhone */
    }
    @media (min-width: 600px) {
    }
    @media (min-width: 1025px) {
        /* big landscape tablets, laptops, and desktops */
        max-width: 800px;
    }
    @media (min-width: 1281px) {
    }
`;

const FeatureImg = styled.img<{
    offset?: string;
    imageWidth?: string;
    mobileOffset?: string;
    mobileDisplay?: string;
    largeDisplay?: string;
}>`
    width: ${props => props.imageWidth || "100%"};
    position: relative;

    left: ${props => props.mobileOffset};
    display: ${props => props.mobileDisplay || "inline-block"};

    @media (min-width: 600px) {
        left: ${props => props.offset};
        display: ${props => props.largeDisplay || "inline-block"};
    }
`;

const Img = (props: any) => (
    <FeatureImg
        {...props}
        src={src(props.name, props.ext)}
    />
);

const FeatureTitle = styled.h3`
    position: relative;
    font-family: Barlow Condensed, sans-serif;
    font-size: 21px;
    font-weight: bold;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.25;
    letter-spacing: normal;
    color: black;
    padding: 0px;
    margin-bottom: 16px;
    text-align: start;

    @media (min-width: 600px) {
        font-size: 28px;
    }
`;

const FeatureText = styled.div<any>`
    font-family: Barlow Condensed, sans-serif;
    font-size: 17px;
    line-height: 1.24;
    letter-spacing: normal;
    color: black;
    text-align: start;
    @media (min-width: 600px) {
        text-align: justify;
        font-size: 21px;
    }
    margin-top: 16px;
`;

const Outer = styled.div<any>`
    width: 100%;
    background: url(${src("rectangle", "png")}) no-repeat center;
    background-size: 300% contain;

    @media (min-width: 1025px) { background-size: contain }
`;

const Features: React.SFC<{
    scrollToPaywall: () => void;
}> = ({scrollToPaywall}) => (

    <Outer>
        <Container>
            <Cell area="title" vMargin="32px" hMargin="16px">
                <H1 dark>Find Success with Fitplan</H1>
            </Cell>
            <Cell area="first-text" hPadding="16px" vPadding="16px">
                <FeatureTitle>
                    Your Personal Training App
                </FeatureTitle>
                <FeatureText>
                    Fitplan is an easy-to-use app with over 65 personal training
                    programs for every level and goal.
                </FeatureText>
            </Cell>
            <Cell area="second-text" hPadding="16px" vPadding="16px">
                <FeatureTitle>
                    Simple Instruction
                </FeatureTitle>
                <FeatureText>
                    Stay motivated with step-by-step workouts designed to help
                    you get healthy and fit.
                </FeatureText>
            </Cell>
            <Cell area="third-text" hPadding="16px" vPadding="16px">
                <FeatureTitle>
                    Results You Can See and Feel
                </FeatureTitle>
                <FeatureText>
                    Make the most out of your gym membership and get the results
                    you want, all in one easy app.
                </FeatureText>
            </Cell>
            <Cell area="first-pic">
                <Img name="clay-light@2x" ext="png" mobileDisplay="none"/>
                <Img name="clay-light@2x" ext="png" largeDisplay="none" imageWidth="calc(150%)"/>
            </Cell>
            <Cell area="second-pic">
                <Img name="clay-white@2x" ext="png" imageWidth="80%"/>
            </Cell>
            <Cell area="third-pic">
                <Img name="results-apple-watch@2x" ext="png" offset={"calc(10%)"}/>
            </Cell>
            <Cell area="subtitle" vMargin="16px">
                <H2 dark>
                    You’re only one workout away from starting your success story!
                    <br />
                    Take Fitplan to The Y today.
                </H2>
            </Cell>
            <Cell area="button" vMargin="8px" hMargin="auto" bMargin="32px">
                <Button type="default" onClick={scrollToPaywall}>
                    <ButtonText>START HERE</ButtonText>
                </Button>
            </Cell>
        </Container>
    </Outer>
);

export default Features;
