import * as React from 'react';
import Proptypes from 'prop-types';
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from 'react-stripe-elements';

import { PayNowButton } from './payNowButton';
import { Button } from '../Button';
import './stripeForm.scss';

const ErrorMessage = ({ error }) =>
  error && error.message ? (
    <span className="error-message">{error.message}</span>
  ) : null;

class StripeForm extends React.Component {
  static defaultProps = {
    handleToken: () => null,
  };
  static propTypes = {
    handleToken: Proptypes.func,
  };
  constructor(props) {
    super(props);

    const paymentRequest = props.stripe.paymentRequest({
      country: 'US',
      currency: props.plan.currency,
      total: {
        label: props.plan.name,
        amount: props.plan.amount,
      },
    }); 

    paymentRequest.on('token', ({ complete, token, ...data }) => {
      if (token) {
        complete('success');
        this.handleToken(token);
      }
    });

    this.state = {
      canMakePayment: false,
      paymentRequest,
      completed: {},
      errors: {},
    };
  }

  componentDidMount() {
    this.state.paymentRequest.canMakePayment().then(result => {
      if (result) {
        this.setState({ canMakePayment: !!result, isApple: result.applePay });
      }
    });
  }

  handleToken(token) {
    this.props.handleToken(token);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.setLoading(true);

    this.props.stripe.createToken().then(({ token, error }) => {
      this.props.setLoading(false)
      if (error) {
        this.setState(state => ({
          errors: {...state.errors, cardNumber: error}
        }));
        return;
      }
      this.handleToken(token);
    }).catch(err => {
      this.props.setLoading(false)
      this.setState(state => ({
        errors: {...state.errors, cardNumber: { message: "An unknown error has occured. Please try again later." }}
      }));
    });
  };

  handleFieldChange = ({ complete, error, elementType }) => {
    this.setState(s => ({
      completed: { ...s.completed, [elementType]: complete },
      errors: { ...s.errors, [elementType]: error },
    }));
  };

  isFormComplete() {
    if (this.props.disabled) return false;

    return ['cardNumber', 'cardExpiry', 'cardCvc'].every(
      element => this.state.completed[element]
    );
  }

  render() {
    const errors = this.state.errors || {};
    return (
      <form className="stripe-form-container" onSubmit={this.handleSubmit}>
        <div className="stripe-form-input">
          <CardNumberElement onChange={this.handleFieldChange} />
          <ErrorMessage error={errors.cardNumber || { message: this.props.error }} />
        </div>
        <div className="stripe-form-input__group">
          <div className="stripe-form-input">
            <CardExpiryElement onChange={this.handleFieldChange} />
            <ErrorMessage error={errors.cardExpiry} />
          </div>
          <div className="spacer" />
          <div className="stripe-form-input">
            <CardCVCElement onChange={this.handleFieldChange} />
            <ErrorMessage error={errors.cardCvc} />
          </div>
        </div>
        <div className="button-container">
          <Button
            loading={this.props.loading}
            disabled={!this.isFormComplete()}
          >
            Pay with credit card
          </Button>
        </div>
        {this.state.canMakePayment ? (
          <>
            <div className="auto-pay__divider">
              <span className="divider__text">or</span>
            </div>
            <div
              className="auto-pay__button-wrapper"
              style={{ overflow: 'hidden', borderRadius: '100px' }}
            >
              <PayNowButton
                type="button"
                disabled={this.props.disabled}
                isApple={this.state.isApple}
                onClick={this.state.paymentRequest.show}
              />
            </div>
          </>
        ) : null}
      </form>
    );
  }
}

export default injectStripe(StripeForm);
