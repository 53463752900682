import * as React from "react";
import styled from "@emotion/styled";
import { src, H1, Cell } from "./common";

const Container = styled.div`
    display: grid;
    width: 100%;
    margin: 16px auto;

    grid-template-areas:
        "title" "lstar" "ltext" "mstar" "mtext" "rstar" "rtext" "lpic" "mpic" "rpic";
    grid-template-rows: auto 22px auto 22px auto 22px auto 1fr 1fr 1fr;

    justify-items: center;
    align-items: start;
    
    @media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ }
    @media (min-width:600px)  { 
        grid-template-areas:
                "title  title   title"
                "lstar  mstar   rstar"
                "ltext  mtext   rtext"
                "lpic   mpic    rpic";
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto 22px auto 1fr;        
    }
    @media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
    @media (min-width:1281px) { max-width: 1200px; }
    
`;

const TestimonialImg = styled.img`
    width: 100%;
    object-fit: contain;
    box-sizing: border-box;
`;

const Img = (props: any) => (
    <TestimonialImg
        {...props}
        src={src(props.name, props.ext)}
    />
);

const Outer = styled.div<any>`
    display: inline-block;
    width: 100%;
    background-color: #f0f0f0;
    padding: 32px 0;
`;

const Quote = styled.blockquote`
    font-family: Barlow Condensed, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.41;
    letter-spacing: 0.2px;
    text-align: center;
    color: black;
`;
  
const QuoteName = styled.div<any>`
    font-size: 12px;
    letter-spacing: 1px;
`;


const StarContainer = styled.div<any>`
    color: #12b587;
    height: 22px;
`;

const Star = styled.span`
    margin: 2px;
`;

const Stars: React.SFC = (props) => (
    <StarContainer>
        <Star className="fa fa-star"/>
        <Star className="fa fa-star"/>
        <Star className="fa fa-star"/>
        <Star className="fa fa-star"/>
        <Star className="fa fa-star"/>
    </StarContainer>
);

const Testimonials: React.SFC = () => (
    <Outer>
        <Container>
            <Cell area="title" bMargin="32px" hMargin="16px">
                <H1 dark>Your Fitplan. Your Success.</H1>
            </Cell>
            <Cell area="lstar"><Stars /></Cell>
            <Cell area="mstar"><Stars /></Cell>
            <Cell area="rstar"><Stars /></Cell>
            <Cell area="ltext">
                <Quote>
                    “I changed my thoughts from I can’t to I can! A positive mind with dedication and @michelle_lewin’s #Fitplans helped me lose 40 lbs. I want you to know that you can do it too! NEVER QUIT. The process works if you make short term goals and change your mindset.”
                    <QuoteName>— Karina K.</QuoteName>
                </Quote>
            </Cell>
            <Cell area="mtext">
                <Quote>
                “Once I accepted that this was going to be a commitment and accepted that this was going to be a life change, I started understanding my body more and realizing that things take time. This is hard work, a clean diet, and following Fitplan App.”
                    <QuoteName>— Roger Z.</QuoteName>
                </Quote>
            </Cell>
            <Cell area="rtext">
                <Quote>
                    “If it doesn’t challenge you it doesn’t change you. Was changing my lifestyle challenging? Definitely. I have been following all of Jen Selter’s Fitplans and have no plans of slowing down.”
                    <QuoteName>— Melissa M.</QuoteName>
                </Quote>
            </Cell>
            <Cell area="lpic" hPadding="16px" vMargin="8px">
                <Img name="transformation-1@2x" ext="png"/>                
            </Cell>
            <Cell area="mpic" hPadding="16px" vMargin="8px">
                <Img name="transformation-2@2x" ext="png"/>                
            </Cell>
            <Cell area="rpic" hPadding="16px" vMargin="8px">
                <Img name="transformation-3@2x" ext="png"/>                
            </Cell>
        </Container>
    </Outer>
);

export default Testimonials;
