import * as React from 'react';
import Checkmark from '../icons/Checkmark';
import { Button } from '../landing/Button';
import '../landing/purchaseComplete.scss';

export const PurchaseComplete = ({ children, link }) => {
  return (
    <section className="purchase-complete">
      <div className="purchase-complete__card">
        <div className="purchase-complete__text-section">
          <Checkmark />
          <div className="text-content">{children}</div>
        </div>
      </div>
      <div className="purchase-complete__button-group">
        <Button to={link}>Download the app</Button>
        <p className="button-group__paragraph">
          Confirmation has been sent to your email. Download and login with your account on Fitplan to get started.
        </p>
      </div>
    </section>
  );
};
