import * as React from 'react';
import { Button } from './Button';
import './hasSubscription.scss';

const WarningIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="14"
    viewBox="0 0 16 14"
  >
    <path
      fill="#FFD12E"
      fillRule="nonzero"
      d="M15.216 12.529L8.882.654a1 1 0 0 0-1.765 0L.784 12.529A1 1 0 0 0 1.667 14h12.667a1 1 0 0 0 .882-1.471zM8 12a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm1-3H7V5h2v4z"
    />
  </svg>
);

export const HasSubscription = props => (
  <section className="has-subscription">
    <h1 className="has-subscription__header">
      <WarningIcon /> <span>You already have a valid subscription.</span>
    </h1>
    <p className="has-subscription__paragraph">
      Open the Fitplan app and login with your account to get started.
    </p>
    <Button to={props.link}>open the app</Button>
  </section>
);
