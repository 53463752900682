import * as React from "react";
import styled from "@emotion/styled";
import { Button } from "@fitplan/components";
import { src, ButtonText, H1, H2, Cell } from "./common";

const Container = styled.div<any>`
    background: url(${src('ymca-banner-desktop', 'jpg')}) no-repeat center center;
    background-size: cover;
    width: 100%;
    font-family: Barlow Condensed, sans-serif;
`;

const HeroBody = styled.div<any>`
    display: grid;
    grid-template-areas: "icons" "title" "subtitle" "button";

    margin: 0 auto;

    align-items: space-evenly;
    justify-items: center;

    padding: 64px;
`;

const IconImg = styled.img`
    width: ${(props: any) => `${props.width || 49}px`};
    height: ${(props: any) => `${props.height || 49}px`};
    margin-left: 16px;
    margin-right: 16px;
    object-fit: contain;
`;

const Icon = (props: any) => (
    <IconImg
        {...props}
        src={src(props.name, props.ext)}
    />
);

const PlusIcons = styled.span`
    position: relative;
    top: -8px;
    width: 24px;
    height: 48px;
    object-fit: contain;
    font-family: Barlow Condensed, sans-serif;
    font-size: 48px;
    font-weight: 800;
    font-style: normal;
    font-stretch: condensed;
    line-height: 38px;
    text-align: center;
    color: #ffffff;
`;

const Hero: React.SFC<{
    scrollToPaywall: () => void;
}> = ({scrollToPaywall}) => (
    <Container>
        <HeroBody>
            <Cell area="icons" tMargin="16px">
                <Icon width={49} height={49} name="fitplan-app-logo@2x" ext="png" />
                <PlusIcons>+</PlusIcons>
                <Icon width={63} height={49} name="ymca-logo@2x" ext="png" />
            </Cell>
            <Cell area="title" vMargin="16px">
                <H1>Mon Valley YMCA Member Discount</H1>
            </Cell>
            <Cell area="subtitle">
                <H2>Reach your goals faster! Take Fitplan to The Y. </H2>
            </Cell>
            <Cell area="button" vMargin="32px">
                <Button type="default" onClick={scrollToPaywall}>
                    <ButtonText>
                        GET STARTED
                    </ButtonText>
                </Button>
            </Cell>
        </HeroBody>
    </Container>
);

export default Hero;