import * as React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { useReducer } from "react";
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";

import { Hero, Features, Testimonials, Subscribe } from "../../components/ymca";
import localize from "../../hoc/Localize";
import SubscriptionFlow from "../../components/ymca/SubscriptionFlow";
import { scroller, Element } from "react-scroll";

const Background = styled.div`
    background-color: #212121;
`;

const Footer = styled.div`
    background-color: #111213;
    padding-bottom: 128px;
`;

interface IState {
    currentPlan: any | null;
}

const initialState: IState = {
    currentPlan: null,
};

function reducer(state: IState, action: { type: string; payload: any }) {
    switch (action.type) {
        case "SELECT_PLAN":
            return {
                ...state,
                currentPlan: action.payload,
            };
        case "GO_HOME":
            return {
                ...state,
                currentPlan: null,
            };
        default:
            return state;
    }
}

const MonValleyYMCA = (props: any) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const ref = React.useRef(null);
    const scrollToPaywall = () => {
        scroller.scrollTo("ymca-subscribe", {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
        });
    };

    return state.currentPlan ? (
        <SubscriptionFlow
            plan={state.currentPlan}
            onClose={() => dispatch({ type: "GO_HOME", payload: null })}
        />
    ) : (
        <>
            <Hero scrollToPaywall={scrollToPaywall} />
            <Features scrollToPaywall={scrollToPaywall} />
            <Testimonials />
            <Element name="ymca-subscribe">
                <Subscribe
                    onSubscribe={(plan: any) =>
                        dispatch({ type: "SELECT_PLAN", payload: plan })
                    }
                />
            </Element>
            <Background>
                <Polygon polygonColor="#111213" borderColor="#00ffb3" />
            </Background>
            <Footer />
        </>
    );
};

export const query = graphql`
    query monValley {
        sanityLegal(slug: { eq: "terms-of-service" }) {
            title {
                _type
                en
                es
            }
            _rawTerms
        }
    }
`;

export default localize(MonValleyYMCA);
