import * as React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import InjectedForm from './stripeForm';
import { stripePublicKey } from "../../../config";

class Stripe extends React.Component {
  constructor(props) {
    super(props);
    this.state = { stripe: null };
  }

  componentDidMount() {
    this.setState({
      stripe: window.Stripe(stripePublicKey),
    });
  }

  render() {
    return this.state.stripe ? (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          <InjectedForm {...this.props} />
        </Elements>
      </StripeProvider>
    ) : null;
  }
}

export default Stripe;
