import * as React from 'react';
import classnames from 'classnames';

const GooglePayLogo = () => (
  <svg
    width="59px"
    height="24px"
    viewBox="0 0 59 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>google-pay-mark_800_gray@1x</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Purchase-Flow"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="purchase-flow2-copy-2"
        transform="translate(-185.000000, -702.000000)"
        fillRule="nonzero"
      >
        <g id="Group-3" transform="translate(105.500000, 702.491025)">
          <g
            id="google-pay-mark_800_gray"
            transform="translate(80.000000, 0.000000)"
          >
            <g id="GPay-logo" transform="translate(0.033107, 0.084906)">
              <g
                id="Pay"
                transform="translate(25.335865, 1.345553)"
                fill="#FFFFFF"
              >
                <path
                  d="M2.14890586,10.1723774 L2.14890586,17.0077844 L0.013264851,17.0077844 L0.013264851,0.134555256 L5.67735625,0.134555256 C7.04363591,0.107644205 8.37012101,0.632409704 9.33845513,1.60120755 C11.2751234,3.43115903 11.394507,6.5124744 9.57722245,8.49043666 C9.49763335,8.57116982 9.41804423,8.65190296 9.33845513,8.73263612 C8.3435913,9.68797844 7.12322501,10.1723774 5.67735625,10.1723774 L2.14890586,10.1723774 Z M2.14890586,2.20670621 L2.14890586,8.10022641 L5.73041564,8.10022641 C6.52630671,8.12713747 7.29566806,7.80420485 7.83952696,7.22561725 C8.95377445,6.05498652 8.92724474,4.17121294 7.77320271,3.04094878 C7.22934381,2.50272776 6.49977701,2.20670621 5.73041564,2.20670621 L2.14890586,2.20670621 Z"
                  id="Shape"
                />
                <path
                  d="M15.7984376,5.08618867 C17.3769548,5.08618867 18.6238509,5.51676551 19.5391256,6.36446361 C20.4544002,7.21216172 20.9054052,8.39624797 20.9054052,9.88981133 L20.9054052,17.0077844 L18.8626182,17.0077844 L18.8626182,15.4065768 L18.7697642,15.4065768 C17.8810191,16.7252183 16.7137123,17.3845391 15.2413138,17.3845391 C13.9944178,17.3845391 12.9332297,17.0077844 12.0975441,16.2542749 C11.2751234,15.5545876 10.8108536,14.5185121 10.8373833,13.4286146 C10.8373833,12.2310728 11.2883882,11.289186 12.1771333,10.5760431 C13.0658783,9.86290027 14.2597149,9.51305661 15.7453782,9.51305661 C17.0188039,9.51305661 18.0534623,9.75525607 18.875883,10.2127439 L18.875883,9.71488948 C18.875883,8.97483558 18.5575265,8.27514824 18.0004029,7.79074932 C17.4300143,7.27943935 16.7004475,6.99687332 15.9443509,6.99687332 C14.7505144,6.99687332 13.8087099,7.50818329 13.1189377,8.53080323 L11.2353288,7.33326145 C12.2434575,5.83969811 13.7689154,5.08618867 15.7984376,5.08618867 Z M13.0393486,13.4689811 C13.0393486,14.0341132 13.3046456,14.5588787 13.7423857,14.8818113 C14.2199203,15.258566 14.8035737,15.4603989 15.400492,15.4469434 C16.3025019,15.4469434 17.1647172,15.0836442 17.80143,14.437779 C18.5044671,13.7650027 18.8626182,12.9711267 18.8626182,12.0561509 C18.1993756,11.5179299 17.270836,11.2488194 16.0769995,11.2622749 C15.2147841,11.2622749 14.4852173,11.4775633 13.9015639,11.8946846 C13.3311753,12.3118059 13.0393486,12.8365714 13.0393486,13.4689811 Z"
                  id="Shape"
                />
                <polygon
                  id="Path"
                  points="32.6315335 5.46294339 25.4950436 22.0805176 23.2930784 22.0805176 25.9460486 16.2677305 21.2635562 5.46294339 23.5849052 5.46294339 26.9674422 13.7515472 27.0072367 13.7515472 30.3101846 5.46294339"
                />
              </g>
              <g id="Super-G">
                <path
                  d="M18.8493533,10.0378221 C18.8493533,9.37850135 18.7962939,8.71918059 18.6901751,8.07331537 L9.68334125,8.07331537 L9.68334125,11.800496 L14.8433683,11.800496 C14.6311307,12.9980377 13.9413584,14.0744798 12.9332297,14.7472561 L12.9332297,17.1692507 L16.0106752,17.1692507 C17.8146949,15.48731 18.8493533,12.9980377 18.8493533,10.0378221 Z"
                  id="Path"
                  fill="#4285F4"
                />
                <path
                  d="M9.68334125,19.5105122 C12.2567224,19.5105122 14.4321579,18.6493585 16.0106752,17.1692507 L12.9332297,14.7472561 C12.0710144,15.3392992 10.9700318,15.6756873 9.68334125,15.6756873 C7.18954925,15.6756873 5.08043795,13.9668356 4.32434144,11.6793962 L1.15404204,11.6793962 L1.15404204,14.182124 C2.77235386,17.4518167 6.07530176,19.5105122 9.68334125,19.5105122 Z"
                  id="Path"
                  fill="#34A853"
                />
                <path
                  d="M4.32434144,11.6793962 C3.92639591,10.4818544 3.92639591,9.17666846 4.32434144,7.96567115 L4.32434144,5.47639892 L1.15404204,5.47639892 C-0.212237617,8.20787061 -0.212237617,11.4371968 1.15404204,14.1686685 L4.32434144,11.6793962 Z"
                  id="Path"
                  fill="#FBBC04"
                />
                <path
                  d="M9.68334125,3.96938005 C11.0496209,3.94246901 12.3628412,4.46723449 13.3444401,5.42257682 L16.0769995,2.65073855 C14.339304,1.00916442 12.0577496,0.107644205 9.68334125,0.134555256 C6.07530176,0.134555256 2.77235386,2.20670621 1.15404204,5.47639892 L4.32434144,7.97912668 C5.08043795,5.67823181 7.18954925,3.96938005 9.68334125,3.96938005 Z"
                  id="Path"
                  fill="#EA4335"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const ApplePayLogo = () => (
  <svg
    width="57px"
    height="24px"
    viewBox="0 0 57 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Apple_Pay_Mark_RGB_052318@1x</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Purchase-Flow"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="purchase-flow2-copy-2"
        transform="translate(-186.000000, -572.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="Group" transform="translate(106.000000, 571.991025)">
          <g
            id="Apple_Pay_Mark_RGB_052318"
            transform="translate(80.000000, 0.500000)"
          >
            <g id="XMLID_34_">
              <g id="XMLID_44_">
                <path
                  d="M10.6372851,3.06321267 C11.2722172,2.26909502 11.7030769,1.20276018 11.589457,0.113122172 C10.66,0.159366516 9.5258371,0.726289593 8.86918552,1.52108597 C8.27959276,2.20167421 7.75773756,3.31262443 7.89375566,4.35660633 C8.93710407,4.44710407 9.97950226,3.83511312 10.6372851,3.06321267"
                  id="XMLID_46_"
                />
                <path
                  d="M11.5776018,4.56045249 C10.0623982,4.470181 8.7741629,5.42036199 8.05054299,5.42036199 C7.32660633,5.42036199 6.21855204,4.6059276 5.02013575,4.6278733 C3.46031674,4.65076923 2.01298643,5.53271493 1.22140271,6.93538462 C-0.406651584,9.74149321 0.791764706,13.9038914 2.37497738,16.189276 C3.14384615,17.3199548 4.07040724,18.5648869 5.29135747,18.5201357 C6.44493213,18.4748869 6.89705882,17.7732127 8.29927602,17.7732127 C9.70045249,17.7732127 10.1077376,18.5201357 11.3288688,18.4975113 C12.5952036,18.4748869 13.3868778,17.3662896 14.1557466,16.2345701 C15.0377376,14.9456561 15.3987783,13.7011312 15.4214932,13.6328054 C15.3987783,13.610181 12.9796833,12.6821267 12.9572398,9.89936652 C12.9343891,7.56932127 14.8565158,6.46104072 14.9469683,6.39230769 C13.8615385,4.78692308 12.1655656,4.6059276 11.5776018,4.56045249"
                  id="XMLID_45_"
                />
              </g>
              <g id="XMLID_35_" transform="translate(19.909502, 1.357466)">
                <path
                  d="M6.77520362,0.0498642534 C10.0684163,0.0498642534 12.36181,2.3199095 12.36181,5.62493213 C12.36181,8.94176471 10.021448,11.2237104 6.69285068,11.2237104 L3.04660633,11.2237104 L3.04660633,17.021991 L0.41199095,17.021991 L0.41199095,0.0498642534 L6.77520362,0.0498642534 Z M3.04660633,9.01230769 L6.06941176,9.01230769 C8.36303167,9.01230769 9.66841629,7.77746606 9.66841629,5.63674208 C9.66841629,3.49624434 8.36303167,2.27294118 6.08117647,2.27294118 L3.04660633,2.27294118 L3.04660633,9.01230769 Z"
                  id="XMLID_41_"
                />
                <path
                  d="M13.0154299,13.5055204 C13.0154299,11.3294118 14.6738462,10.0826697 17.7317647,9.89461538 L21.0132127,9.69447964 L21.0132127,8.75366516 C21.0132127,7.37751131 20.1075566,6.62475113 18.4963348,6.62475113 C17.1671041,6.62475113 16.2026697,7.30692308 16.0028054,8.35371041 L13.6268326,8.35371041 C13.6976018,6.15420814 15.7675566,4.55479638 18.5668778,4.55479638 C21.5778733,4.55479638 23.5420814,6.13085973 23.5420814,8.57714932 L23.5420814,17.021991 L21.1073303,17.021991 L21.1073303,14.9874208 L21.0485973,14.9874208 C20.3546154,16.3164253 18.8255204,17.1513122 17.1671041,17.1513122 C14.7207692,17.1513122 13.0154299,15.6929864 13.0154299,13.5055204 Z M21.0132127,12.4115837 L21.0132127,11.458733 L18.0845701,11.6470136 C16.4379638,11.7527602 15.5795023,12.3643891 15.5795023,13.4347511 C15.5795023,14.4697285 16.4733484,15.140362 17.8728959,15.140362 C19.6605882,15.140362 21.0132127,13.9991855 21.0132127,12.4115837 Z"
                  id="XMLID_38_"
                />
                <path
                  d="M25.780362,21.5620814 L25.780362,19.5275113 C25.9450679,19.5506787 26.3448416,19.5742534 26.5564706,19.5742534 C27.7209955,19.5742534 28.3795928,19.0805882 28.7795928,17.8101357 L29.0148416,17.0573756 L24.5571041,4.70769231 L27.3092308,4.70769231 L30.4143891,14.728371 L30.4731222,14.728371 L33.5782805,4.70769231 L36.2600905,4.70769231 L31.6376471,17.6808145 C30.579095,20.6564253 29.3676018,21.6328507 26.8035294,21.6328507 C26.6036652,21.6328507 25.9566516,21.609276 25.780362,21.5620814 Z"
                  id="XMLID_36_"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const PayNowButton = ({ isApple, className, ...props }) => (
  <button {...props} className={classnames('pay-now-button', className)}>
    {isApple ? <ApplePayLogo /> : <GooglePayLogo />}
  </button>
);
