import * as React from "react";
import { useState, useEffect } from 'react';
import axios from 'axios';
import classnames from 'classnames';
import { Icon } from 'react-icons-kit';
import { cancelCircle } from 'react-icons-kit/icomoon/cancelCircle';
import Input from '../landing/Input';
import { Button } from '../landing/Button';
import StripeForm from '../landing/stripe/stripe';
import { urls, SNAP_PIXEL_KEY } from '../../config';
import { getAccessToken } from '../../utils/auth';

import '../landing/purchase.scss';
import { ymcaAnalytics } from "./analytics";

export const Purchase: React.SFC<{
    plan: any,
    onComplete: () => void,
    activeView: boolean,
    stepText: string
}> = props => {
  const { plan, onComplete } = props;
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token && plan) {
      setLoading(true);
      const accessToken = getAccessToken();

      axios({
        method: 'post',
        url: urls.submitPayment,
        data: {
            couponCode: null,
            stripePlanId: plan.id,
            stripeToken: token.id,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then(e => {
          setLoading(false);
          if (e.data.error) {
            setError(e.data.error);
          } else {
            ymcaAnalytics.purchaseComplete(plan.id, token.id, plan.currency, plan.amount);
            onComplete();
          }
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.error) {
            setError(err.response.data.error);
          }
          setLoading(false);
        });
    }
  }, [token, plan, onComplete]);

  return (
    <section className="purchase">
      <div
        className={classnames('purchase__form', {
          'purchase__form--active': props.activeView,
        })}
      >
        <h1 className="purchase__form-header">{props.stepText}</h1>
        <StripeForm
          disabled={!props.activeView}
          handleToken={(token: string) => setToken(token)}
          setLoading={setLoading}
          loading={loading}
          plan={props.plan}
          error={error ? "Please check your credit card number" : null}
        />
      </div>
    </section>
  );
};
