import * as React from "react";
import FacebookLogin from "react-facebook-login";

import ClosedView from "./ClosedView";
import Input, { Radio } from "./Input";
import { Button } from "./Button";
import { facebookAppId } from "../../config";
import "./signup.scss";
import { SignUpController } from "@fitplan/lib/components/UserAuth/SignUpController";
import { LogInController } from "@fitplan/lib/components/UserAuth/LogInController";
import { LoginFormStrings } from "../strings/LoginFormStrings";

const initialState = {
    loading: false,
    accountCreation: true,
};

function reducer(state, action) {
    switch (action.type) {
        case "HAVE_ACCOUNT":
            return { ...state, accountCreation: false };
        case "CANCEL_LOGIN":
            return { ...state, accountCreation: true };
        case "LOADING":
            return { ...state, loading: true };
        case "STEP_COMPLETE":
            return { ...state, loading: false };
        default:
            return state;
    }
}

export const SignUp = props => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const [formState, setFormState] = React.useState({});
    const { accountCreation, loading, formError } = state;

    const [signUpForm, loginForm] = props.forms;
    const [name, email, password, passwordRepeat] = signUpForm.formFields;
    const [signUpButton, loginButton] = signUpForm.buttons;
    const [signIn, cancel] = loginForm.buttons;

    return props.activeView ? (
        <section className="sign-up">
            {accountCreation ? (
                <SignUpController
                    onComplete={user => {
                        window.snaptr && window.snaptr("track", "SIGN_UP");
                        window.fbq &&
                            window.fbq("track", "CompleteRegistration");
                        props.onComplete();
                    }}
                >
                    {signup => (
                        <form
                            id="sign-up"
                            className="sign-up__form"
                            onSubmit={signup.onSubmit}
                        >
                            <h1 className="sign-up__form-header">
                                {props.stepText}
                            </h1>
                            <Input
                                key="first_name"
                                {...name}
                                label={false}
                                {...signup.firstName}
                                error={signup.errors.firstName}
                            />
                            <Input
                                key="last_name"
                                {...name}
                                label={false}
                                {...signup.lastName}
                                error={signup.errors.lastName}
                            />
                            <div className="radio-group-wrapper">
                                {signUpForm.radioGroup.map((field, i) => (
                                    <Radio
                                        {...field}
                                        key={i}
                                        {...signup.gender[field.value]}
                                    />
                                ))}
                            </div>
                            <Input
                                {...email}
                                label={false}
                                {...signup.email}
                                error={signup.errors.email}
                            />
                            <Input
                                {...password}
                                label={false}
                                error={signup.errors.password}
                                {...signup.password}
                            />
                            <Input
                                {...passwordRepeat}
                                label={false}
                                {...signup.confirmPassword}
                                error={signup.errors.confirmPassword}
                            />
                            <div className="input-group__error-message">
                                {signup.errors.globalError && (
                                    <p className="error-message__text">
                                        {signup.errors.globalError}
                                    </p>
                                )}
                            </div>
                            <br />
                            <Button
                                disabled={signup.submitDisabled}
                                loading={signup.isLoading}
                            >
                                {signUpButton.text}
                            </Button>
                            <div style={{ width: "80%", margin: "0 auto" }}>
                                {signUpForm.altText}
                                <Button
                                    text
                                    type="button"
                                    onClick={() =>
                                        dispatch({ type: "HAVE_ACCOUNT" })
                                    }
                                >
                                    {loginButton.text}
                                </Button>
                            </div>
                        </form>
                    )}
                </SignUpController>
            ) : (
                <LoginFormStrings>
                    <LogInController
                        onComplete={user => {
                            props.onComplete();
                        }}
                    >
                        {login => (
                            <form
                                id="sign-in"
                                className="sign-up__form"
                                onSubmit={login.onSubmit}
                            >
                                <h1 className="sign-up__form-header">
                                    2. Login To Your Account
                                </h1>
                                <FacebookLogin
                                    appId={facebookAppId}
                                    cssClass={`signup__button facebook ${
                                        login.isLoading ? "loading" : null
                                    }`}
                                    containerStyle={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginBottom: "30px",
                                    }}
                                    autoLoad={false}
                                    fields="email"
                                    textButton={"Login with Facebook"}
                                    authType={"reauthenticate"}
                                    disableMobileRedirect={true}
                                    {...login.facebookLogin}
                                />
                                <div
                                    className="signup__line-container"
                                    style={{
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                    }}
                                >
                                    <hr className="signup__line" />
                                    or
                                    <hr className="signup__line" />
                                </div>
                                <Input
                                    key="email"
                                    {...email}
                                    {...login.email}
                                    label={false}
                                />
                                <Input
                                    {...password}
                                    {...login.password}
                                    label={false}
                                />
                                <div className="input-group__error-message">
                                    {login.error && (
                                        <p className="error-message__text">
                                            {login.error}
                                        </p>
                                    )}
                                </div>
                                <Button
                                    disabled={login.submitDisabled}
                                    loading={login.isLoading}
                                >
                                    {signIn.text}
                                </Button>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Button
                                        cancel
                                        onClick={() =>
                                            dispatch({ type: "CANCEL_LOGIN" })
                                        }
                                    >
                                        {cancel.text}
                                    </Button>
                                </div>
                            </form>
                        )}
                    </LogInController>
                </LoginFormStrings>
            )}
        </section>
    ) : props.isComplete ? (
        <ClosedView stepText={props.stepText} />
    ) : null;
};
