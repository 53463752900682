import styled from "@emotion/styled";

export const src = (name: string, ext: string) => (`/images/partners/ymca/mon-valley/${name}.${ext}`);

export const ButtonText = styled.span`
    height: 24px;
    font-family: Barlow Condensed, sans-serif;
    font-size: 21px;
    font-weight: bold;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
`;

export const H1 = styled.div<{
    dark?: boolean
}>`
    font-family: Barlow Condensed, sans-serif;
    font-size: 48px;
    font-weight: bold;
    font-stretch: condensed;
    line-height: 56px;
    text-align: center;
    color: ${props => props.dark ? "#000000" : "#ffffff"};
    margin: 0;
    padding: 0;
`;

export const H2 = styled.div<{
    dark?: boolean
}>`
    font-family: Barlow, sans-serif;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: ${props => props.dark ? "#000000" : "#ffffff"};
    margin: 0;
    padding: 0;
`;


export const Cell = styled.div<{
    row?: string;
    column?: string;
    area?: string;
    vPadding?: string;
    hPadding?: string;
    lPadding?: string;
    rPadding?: string;
    tPadding?: string;
    bPadding?: string;
    vMargin?: string;
    hMargin?: string;
    lMargin?: string;
    rMargin?: string;
    tMargin?: string;
    bMargin?: string;
    justifySelf?: string;
    alignSelf?: string;
    fontSize?: string;
}>`
    box-sizing: border-box;
    grid-row: ${props => props.row};
    grid-column: ${props => props.column};
    grid-area: ${props => props.area};
    padding: ${({vPadding, hPadding, lPadding, rPadding, tPadding, bPadding}) => {
        if (lPadding || rPadding || tPadding || bPadding) {
            return `${tPadding || vPadding || "0"} ${rPadding || hPadding || "0"} ${bPadding || vPadding || "0"} ${lPadding || hPadding || "0"}`;
        } else if (vPadding || hPadding) {
            return `${vPadding || "0"} ${hPadding || "0"}`;
        }
        return "0";
    }};
    margin: ${({vMargin, hMargin, lMargin, rMargin, tMargin, bMargin}) => {
        if (lMargin || rMargin || tMargin || bMargin) {
            return `${tMargin || vMargin || "0"} ${rMargin || hMargin || "0"} ${bMargin || vMargin || "0"} ${lMargin || hMargin || "0"}`;
        } else if (vMargin || hMargin) {
            return `${vMargin || "0"} ${hMargin || "0"}`;
        }
        return "0";
    }};

    justify-self: ${props => props.justifySelf};
    align-self: ${props => props.alignSelf};

    font-size: ${props => props.fontSize};
`;
