import * as React from 'react';
import Checkmark from '../icons/Checkmark';
import './closedView.scss';

const intervalMap = {
  year: 'yearly',
};

const ClosedView = ({
  onClick,
  stepText,
  description,
  totalText,
  amount,
  currencySymbol
}) => (
  <div className="closed-view">
    <button className="closed__content" onClick={onClick}>
      <div className="closed__header-group">
        <h1 className="closed__header">{stepText}</h1>
        <Checkmark />
      </div>
      {description && (
        <div className="closed__text-group">
          <p className="closed__text-description">{description}</p>
          <p>
            <span className="closed__text-total">{`${totalText} ${currencySymbol}${amount}`}</span>
          </p>
        </div>
      )}
    </button>
  </div>
);

export default ClosedView;
