import { SNAP_PIXEL_KEY } from "../../config";

export interface IYmcaAnalytics {
    addToCart: (planId: string) => void;
    purchaseComplete: (planId: string, transactionId: string, currency: string, amount: number) => void;
}

export const ymcaAnalytics: IYmcaAnalytics = {
    addToCart: (planId: string) => {
        if (process.env.GATSBY_ACTIVE_ENV === 'production') {
            const { ga, snaptr, fbq } = window as any;
            ga('send', {
                hitType: 'event',
                eventCategory: 'landing-campaign',
                eventAction: 'add-to-cart',
                eventLabel: planId
            });
            snaptr('init', SNAP_PIXEL_KEY ,{})
            snaptr('track', 'ADD_CART');
            fbq("track", "AddToCart");
        }
    },
    purchaseComplete: (planId: string, transactionId: string, currency: string, amount: number) => {
        if (process.env.GATSBY_ACTIVE_ENV === 'production') {
            const { ga, snaptr, fbq } = window as any;
            ga('send', {
                hitType: 'event',
                eventCategory: 'landing-campaign',
                eventAction: 'purchase-complete',
                eventLabel: planId
            });
            snaptr('init', SNAP_PIXEL_KEY, {})
            snaptr('track', 'PURCHASE', {
                'currency': currency,
                'price': amount / 100,
                'transaction_id': transactionId,
            });
            fbq('track', 'Purchase', {
                value: amount / 100,
                currency: currency.toUpperCase(),
                content_ids: transactionId
            });
        }
    }
}