import * as React from 'react';
import { useReducer, useState, Fragment } from 'react';
import {Link} from "@fitplan/lib/components/Link";

import classnames from 'classnames';
import isAfter from 'date-fns/isAfter';
import { graphql } from 'gatsby';
import {
  Plan,
  SignUp,
  HasSubscription,
} from '../../components/landing';
import { isLoggedIn, getUser } from '../../utils/auth';
import localize from '../../hoc/Localize';
import "../../templates/landing.scss";
import { SNAP_PIXEL_KEY } from '../../config';

import { english as sanityPlan } from "./data/plans";
import ClosedView from '../landing/ClosedView';
import plan from '../../templates/plan';
import { Purchase } from "./PurchaseFlow"
import { PurchaseComplete } from "./PurchaseComplete";
import { AuthFormStrings } from '../strings/AuthStrings';

interface IState {
    activeView: string;
    stepsComplete: string[];
    plan?: any;
}

const initialState: IState = {
  activeView: 'signup',
  stepsComplete: []
};

function reducer(state: IState, action: any) {
  switch (action.type) {
    case 'SUBMIT_PLAN':
      return {
        ...state,
        activeView: isLoggedIn() ? 'purchase' : 'signup',
        plan: action.payload,
        stepsComplete: ['plan', isLoggedIn() && 'signup'],
      };
    case 'AUTH_COMPLETE':
      return {
        ...state,
        activeView: 'purchase',
        stepsComplete: [...state.stepsComplete, 'signup'],
      };
    case 'PURCHASE_COMPLETE':
      return {
        ...state,
        activeView: 'start',
        stepsComplete: [...state.stepsComplete, 'purchase'],
      };
    default:
      return state;
  }
}

const userHasSubscription = (user: any) => {
  if (user && user.paymentStoreType) {
    return isAfter(new Date(user.paymentExpirationTimestamp), new Date());
  }
};

const SubscriptionFlow: React.SFC<{plan: any, onClose: () => void }> = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [subscribe, setSubscribe] = useState(true);

  return (
    <div
      className={classnames('landing-page', {
        'landing-page--progress': state.activeView !== 'plan',
      })}
    >
      <noscript>
        <img
          height={1}
          width={1}
          style={{display: "none"}}
          src={`https://www.facebook.com/tr?id=838071729653756&ev=PageView&noscript=1`}
          alt=""
        />
      </noscript>
      {!state.stepsComplete.includes('purchase') ? (
        <AuthFormStrings>
          <ClosedView
            stepText="1. Select Your Plan"
            description={props.plan.name}
            totalText="Total:"
            amount={props.plan.amount / 100}
            currencySymbol={"$"}
            onClick={() => props.onClose()}
          />
          {state.activeView !== 'plan' ? (
            <AuthFormStrings>
              <SignUp
                activeView={state.activeView === 'signup'}
                stepText={sanityPlan.accountStepText}
                forms={sanityPlan.accountForms}
                onComplete={() => dispatch({ type: 'AUTH_COMPLETE' })}
                isComplete={state.stepsComplete.includes('signup')}
              />
              {!userHasSubscription(getUser()) ? (
                <Fragment>
                  <Purchase
                    activeView={state.activeView === 'purchase'}
                    stepText={sanityPlan.purchaseStepText}
                    plan={props.plan}
                    onComplete={() => dispatch({ type: 'PURCHASE_COMPLETE' })}
                  />

                  <div style={{ padding: '16px' }}>
                    <div className="checkbox-container">
                      <label>
                        <input
                          type="checkbox"
                          checked={subscribe}
                          onChange={() => {
                            setSubscribe(s => !s);
                          }}
                        />
                        <span className="checkmark" />
                        Yes, I want to receive news, updates and discouts from
                        Fitplan
                      </label>
                    </div>
                  </div>
                  <div className="terms-links">
                    By creating your account, you agree to Fitplan's{' '}
                    <Link to="../../terms-of-service">Terms of Service</Link>{' '}
                    and <Link to="../../privacy-policy">Privacy Policy</Link>.
                  </div>
                </Fragment>
              ) : (
                <HasSubscription link={sanityPlan.branchDeepLink} />
              )}
            </AuthFormStrings>
          ) : null}
        </AuthFormStrings>
      ) : (
        <PurchaseComplete
          link={sanityPlan.branchDeepLink}
        >
          Purchase Successful!
        </PurchaseComplete>
      )}
    </div>
  );
};

export default SubscriptionFlow;